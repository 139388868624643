<template>
	<div class="first">
		<div style="width: 60%;position: absolute;top:25%;left: 20%;height: 50%;">
		<h1 style="text-align: center;font-size: 10vw; background-image: linear-gradient(to right, #E5CDC3, #6047E0);
      -webkit-background-clip: text;
      color: transparent;">个人分享网</h1>
		<el-input v-model="username" placeholder="请输入姓名" style="margin-top: 8%;"></el-input>
		<el-input show-password style="margin-top: 8%;width: 100%;" v-model="password" placeholder="通行证为4位出生日期"></el-input>
		<center><el-button round type="warning" @click="login()" style="margin-top: 10%;">开启旅行</el-button>
		</center>
	</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				username:"",
				password:"",
			}
		},
		methods:{
			login(){
				if(this.username == '鲍一娟' && this.password == '1030'){
				localStorage.setItem("username",this.username)
				localStorage.setItem("password",this.password)
				this.$message.success('登录成功')
				this.$router.push('/index')
			}else{
				 this.$message.error('用户名或通行证错误！');
			}
			}
		},
		mounted(){
			
			 document.
			 querySelector("body").
			 setAttribute("style","background-size:100%;background-image: url("+require('../assets/780.jpg')+");background-repeat:no-repeat;")
		},
		beforeDestroy() {
			document.querySelector("body").removeAttribute("style")
		}
		
	}
</script>

<style>
	.first{

		padding-top: 15%;
		width:100%;
	
	}
	
</style>

<template>
  <div>
   <router-view></router-view>
   
   <audio :src="require('./assets/song.mp3')" loop ref="MusicPlay"></audio>
   <i @click="musicPlay()" class="el-icon-video-play" style="position: absolute;right: 5%;top:2%;" v-if="!isopen">播放音乐</i>
   <i @click="musicStop()" class="el-icon-video-pause" style="position: absolute;right: 5%;top:2%;" v-if="isopen">暂停音乐</i>
  <bottom style="position: fixed;right: 0%;bottom:2%;"></bottom>
  </div>
</template>

<script>
	import bottom from './components/bottom.vue'
export default {
	data(){
		return{
			isopen:false,
		}
	},
  name: 'App',
  methods:{

	  musicPlay(){
		  this.isopen = true
		  this.$refs.MusicPlay.play()
	  },
	  musicStop(){
		  this.isopen = false
		  this.$refs.MusicPlay.pause()
	  }
  },
  components:{
	  bottom
  }
  
}
</script>

<style>
</style>

<template>
	<div style="width:100%;">
				 	
		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33078102100255" style="text-decoration:none;color:black;display: inline-block;font-size: 1.2vw;margin-left: 2%;">
		<img src="../assets/beian.png" style="float: left;"/><p style="display: inline-block;">浙公网安备33078102100255号</p></a>
				 	
		<a href="https://beian.miit.gov.cn" target="_blank" style="color: black;text-decoration: none;font-size: 1.2vw;margin-left: 2%;">浙ICP备2022030065号</a>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style>
</style>

<template>
	<div style="border: 1px rgba(125,125,125,0) solid;">
		<img src="../assets/12.jpg" style="width: 30%;position: absolute;left:35%;top:40%;"/>
<!-- 		<img src="../assets/7.jpg" id="img1" style="width: 30%;position: absolute;left:8%;top:30%;"/>
		<img src="../assets/8.jpg" id="img2" style="width: 30%;position: absolute;right:10%;top:48%;"/>
		<img src="../assets/9.jpg" id="img3" style="width: 30%;position: absolute;left:8%;top:45%;"/>
		<img src="../assets/10.jpg" id="img4" style="width: 30%;position: absolute;right:8%;top:28%;"/> -->
		<el-button type="danger" style="position: absolute;width30%;left:35%;bottom: 30%;" @click="goback()">回到起点</el-button>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				timer:'',
			}
		},
		methods:{
		changerotate(){
			clearTimeout(this.timer)
			this.timer = setTimeout(()=>{
			document.getElementById("img1").style.transform = "rotate(45deg)"
			document.getElementById("img2").style.transform = "rotate(45deg)"
			document.getElementById("img3").style.transform = "rotate(-45deg)"
			document.getElementById("img4").style.transform = "rotate(-45deg)"
			clearTimeout(this.timer)
			},10)
	

		},
			goback(){
				this.$router.replace("/index")
			}
		},
		mounted(){
			// for(var i = 1; i <= 4; i++){
			// 	document.getElementsByTagName("img")[i].style.transition = "3s";
			// }
			document.
			querySelector("body").
			setAttribute("style","background-image: url("+require('../assets/11.jpg')+");background-size:100%;")
			
			// this.changerotate()
		},
		beforeDestroy(){
			clearInterval(this.timer)
			document.querySelector("body").removeAttribute("style")
		}
	}
</script>

<style>
</style>

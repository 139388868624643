<template>
	<div style="border: 1px rgba(125,125,125,0) solid;">
	<div style="width: 80%;height: 55%;position: absolute;background-color: rgba(255,255,255,0.2);left: 10%;top:22%;">
	<p v-if="showit == 'first'" v-for="(item,index) in wenan1" :key="index">
		{{item}}
	</p>
	<!-- <p v-if="showit == 'second'" v-for="(item,index) in wenan2" :key="index">
		{{item}}
	</p>
  <p v-if="showit == 'third'" v-for="(item,index) in wenan3" :key="index" style="width: 45%;margin: auto;margin-top: 10%;">
	  {{item}}
  </p>
  <p v-if="showit == 'fourth'" v-for="(item,index) in wenan4" :key="index" style="width: 45%;margin: auto;margin-top: 10%;">
  	  {{item}}
  </p> -->
     <img src="../assets/3.jpg" style="width: 30%;position: absolute;right:10%;bottom: 5%;opacity: 0;" id="img1" v-if="showit == 'first'"/>
	<!-- <img src="../assets/4.jpg" style="width: 30%;position: absolute;right:10%;bottom: 5%;opacity: 0;" id="img3" v-if="showit == 'third'"/>
	  <img src="../assets/5.jpg" style="width: 30%;position: absolute;right:10%;bottom: 5%;opacity: 0;" id="img2" v-if="showit == 'second'"/> -->
	</div>
	
	 <el-button type="danger" round style="position: absolute;bottom: 10%;left: 35%;width: 30%;" @click="nextgo()">下一页</el-button>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				timer:'',
				op:0,
				showit:'first',
				wenan1:["没有零点的祝福，没有煽情的语录，感谢所有的馈赠和成长，生日快乐。","又长大一岁了，希望以后的日子里会越来越顺,越来越幸福，生日快乐。","愿你所有的想法都能心想事成，愿所有的美好都能如期而至，生日快乐。"],
				wenan2:["也许以前不是那么顺利，但是你要知道万物守恒定律，以后的你肯定会幸运。","有时候不要学着压抑自己，释放出来，也是一条可以选择的路。","好好加油啊，时间过的很快的，有时候对于不在乎的人，就是需要没心没肺。但是永远把家里人剔除在外。"],
				wenan3:["皇冠裙摆星空装，","彩烛蛋糕主角妆。","回头笑笑伤心事，","抬头可期花季时。"],
				wenan4:["王宫喜迎仙子降，","婷花惊的神迷茫。","生来不弱男子样，","日渐摆脱霓虹伤。","快时眨眼一逝间，","乐颜永葆在身边。"]
			}
		},
	methods:{
		showimg(){
			clearInterval(this.timer)
			this.timer = setInterval(()=>{
				if(this.op >= 1){
					clearInterval(this.timer)
				}
				this.op += 0.01
				if(this.showit == 'first'){
				document.getElementById("img1").style.opacity = this.op
				}
				else if(this.showit == 'second'){
					document.getElementById("img2").style.opacity = this.op
				}
				else if(this.showit == 'third'){
					document.getElementById("img3").style.opacity = this.op
				}
			},20)
		},
		nextgo(){
			clearInterval(this.timer)
			this.op = 0
			this.$router.push("/picturewall")
			// if(this.showit == 'first'){
			// this.showit = "second"
			// }else if(this.showit == 'second'){
			// 	this.showit = 'third'
			// }else if(this.showit == "third"){
			// 	this.showit = 'fourth'
			// }else{
			// 	this.$router.push("/picturewall")
			// }
			
			this.showimg()
		}
	},
		mounted(){
			document.
			querySelector("body").
			setAttribute("style","background-image: linear-gradient(to right, #E5CDC3, #6047E0);")
			
			this.showimg()
		},
		beforeDestroy(){
			clearInterval(this.timer)
			document.querySelector("body").removeAttribute("style")
		}
	}
</script>

<style scoped>
	p{
		width:80% ;
		margin: auto;
		margin-top: 10%;
	
	}
</style>

import VueRouter from 'vue-router'
import login from '../view/login.vue'
import index from '../view/index.vue'
import say from '../view/say.vue'
import picturewall from '../view/picturewall.vue'
const router =  new VueRouter({
	mode:'history',
	routes:[
	{
		path: '/',
		redirect: '/index'
	    
	},
	{
		path:'/index',
		component: index
		 
	},
	{
		path:'/login',
		component: login
		 
	},
	{
		path:'/say',
		component: say
	},
	{
		path:'/picturewall',
		component: picturewall
	}
		
	]
	
	
})


router.beforeEach((to,from,next)=>{
if(to.path == '/index' || to.path == '/say' || to.path == '/picturewall'){
	if(localStorage.getItem('username') == '鲍一娟' && localStorage.getItem('password') == '1030'){
		next()
	}else{
		router.replace('/login')
	
 	}
}else if(to.path == '/login'){
	next()
}
})

export default router
<template>
	<div style="border: 1px solid rgba(125,125,125,0);">
		<img src="../assets/1.png" style="width: 70%;position: absolute;top:20%;left: 17%;"/>
		<img src="../assets/2.png" style="width: 70%;position: absolute;top:19%;left: 17%;opacity: 0;" id="img1"/>
		<el-button type="danger" plain round style="position: absolute;top:50%;left:40%;" @click="unfire()">吹灭蜡烛</el-button>
		<el-button type="danger" style="position: absolute;top:60%;left: 12%;" @click="golike()">我很喜欢</el-button>
		<el-button type="danger" style="position: absolute;top:60%;right: 12%;" @click="unlike()">我不喜欢</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
			fireTime:'',
			op:0,
			isunfire:'',
			}
		},
		methods:{
		     fire(){
				 this.isunfire = false
				 clearInterval(this.fireTime)
				 this.fireTime = setInterval(()=>{
				 	if(this.op >= 1){
				 		clearInterval(this.fireTime)
				 	}
					this.op += 0.01;
				    document.getElementById("img1").style.opacity = this.op;
				 },30)
			 },
			 unlike(){
				 this.$message.warning("不喜欢无效")
			 },
			 unfire(){
				 if(!this.isunfire){
				 var choose = confirm("吹蜡烛前一定要许愿哦！")
				 if(choose == true){
					 this.isunfire = true
					 clearInterval(this.fireTime)
					 this.fireTime = setInterval(()=>{
					 	if(this.op <= 0.01){
					 		clearInterval(this.fireTime)
					 	}
					 	this.op -= 0.01;
					    document.getElementById("img1").style.opacity = this.op;
					 },10)
				 }
			 }else{
				  this.$message.success("火花已经去点亮你的愿望啦~");
				 
			 }
			 },
			 golike(){
				 this.$router.push("/say")
			 }
		},
		mounted(){
		this.fire()
		document.
		querySelector("body").
		setAttribute("style","background-image: linear-gradient(to right, #E5CDC3, #6047E0);")
		},
		beforeDestroy() {
			clearInterval(this.fireTime)
			document.querySelector("body").removeAttribute("style")
		}
		
		
	}
</script>

<style>
</style>
